import React from "react";
import './Actions.css';

export default function Actions (props) {

  return (
    <div className="actions-box">
      <div className="actions-box-top">
        <h3 className='actions-title'>{ 'Balance'  } <span>{ `(${ props.chain })` }</span></h3>
        <div className='balance'>{ props.amount } algo </div>
      </div>
      <div className="actions-button-box">
        <h3 className='actions-title'>{ 'Actions' }</h3>

        <div className='action-buttons'>
          <button className="action-button" onClick={ handleClick.bind(this, 1) }>
            { '1. Sign pay txn' }
          </button>
          <button className="action-button" onClick={ handleClick.bind(this, 2) }>
            { '2. Sign asset transfer txn' }
          </button>
          <button className="action-button" onClick={ handleClick.bind(this, 3) }>
            { '3. Sign asset opt-in txn' }
          </button>
        </div>

      </div>
    </div>
  );

  function handleClick(index) {
    props.onActionClick(index);
  }
}
