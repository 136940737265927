import algosdk from 'algosdk';

export const TEST_NET_CHAIN = 'testnet';
export const MAIN_NET_CHAIN = 'mainnet';

export function ellipseAddress(address = "", width = 4) {
  return `${address.slice(0, width)}...${address.slice(-width)}`;
}

export function sdkClient (chain) {
  switch ( chain ) {
    case TEST_NET_CHAIN:
      return (new algosdk.Algodv2("", "https://testnet-api.algonode.cloud", ''));
    case MAIN_NET_CHAIN:
      return (new algosdk.Algodv2("", "https://mainnet-api.algonode.cloud", ''));
    default:
      throw new Error(`Unknown cain type ${chain}`);
  }
}

export async function getAccountInfo(address, chain) {
  return (await sdkClient(chain).accountInformation(address).do());
}
