import './Balances.css';
import Header from '../header/Header';
import Actions from '../actions/Actions';
import { getAccountInfo } from '../../helpers/util';
import { useState, useEffect } from 'react';

export default function Balances (props) {
  const [amount, setAmount]        = useState(null);
  const {address, chain, accounts} = props;

  useEffect(() => {
    getAccountInfo(props.address, props.chain).then(data => {
      setAmount(data.amount / 1000000);
    });
  }, [address, chain]);

  return (
    <div className="balance-wrapper">
      <div className="balance-wrapper-inner">
        <Header onDisconnect={ props.onDisconnect }
                onAddressChange={ props.onAddressChange }
                onChainChange={ props.onChainChange }
                accounts={ accounts }
                address={ address }/>
        <Actions onActionClick={ props.onActionClick } amount={ amount } chain={ chain }/>
      </div>
    </div>
  );
}
