import React from 'react';
import './Header.css';
import * as blockies from 'blockies-ts';
import { ellipseAddress, MAIN_NET_CHAIN, TEST_NET_CHAIN } from '../../helpers/util';
import { useState } from 'react';


export default function Header (props) {

  const {address, accounts}                 = props;
  const imgSrc                              = blockies.create({seed: address}).toDataURL();
  const [chain, setChain]                   = useState(TEST_NET_CHAIN);
  const [selectedOption, setSelectedOption] = useState('');

  const handleChange = (event) => {
    setChain(event.target.value);
    props.onChainChange(event.target.value);
  };

  return (
    <div className="haeder-box">
      <div className="haeder-box-inner">
        <div className="account-info">
          <img className="image" src={ imgSrc } alt={ address }/>
          <div className="address">{ ellipseAddress(address) }</div>
        </div>

        <div className="chain-select-box">
          <span>Connect to</span>
          <select className="chain-select" value={ chain } onChange={ handleChange }>
            <option value={ TEST_NET_CHAIN }>TestNet</option>
            <option value={ MAIN_NET_CHAIN }>MainNet</option>
          </select>
        </div>

        <button className="disconnect-button" onClick={ props.onDisconnect }>
          { 'Disconnect' }
        </button>

        { accounts.length > 1 &&

          <div className="account-addresses-box">

            <div className="account-addresses-box">
              <span>Choose account</span>
              <select className="accounts-select"
                      value={ selectedOption }
                      onChange={ handleAddressChange }>
                { accounts.map(o => (
                  <option key={ o } value={ o }>{ ellipseAddress(o, 10) }</option>
                )) }
              </select>
            </div>

          </div>
        }

      </div>
    </div>
  );

  function handleAddressChange (event) {
    const address = event.target.value;
    setSelectedOption(address);
    props.onAddressChange(address);
  }
}

