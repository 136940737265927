import React from "react";
import './Landing.css';

export default function Landing (props) {
  return (
    <div className="landing-wrapper">
      <div className="landing-wrapper-inner">
        <h3 className='landing-title'>{ 'Defly WalletConnect Demo' }</h3>
        <button className="connect-button" onClick={ props.onClick }>
          { 'Connect to Defly Wallet' }
        </button>
      </div>
    </div>
  );
}
