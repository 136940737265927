import { DeflyWalletConnect } from '@blockshake/defly-connect';
import { useState, useEffect } from 'react';
import './App.css';
import Landing from './components/landing/Landing';
import Balances from './components/balances/Balances';
import { getScenario } from './helpers/scenarios';
import { sdkClient, TEST_NET_CHAIN } from './helpers/util';

const deflyWallet = new DeflyWalletConnect();

export default function App () {
  const [accounts, setAccounts] = useState(null);

  const [selectedAddress, setSelectedAddress]     = useState(null);
  const [chain, setChain]                       = useState(TEST_NET_CHAIN);
  const isConnectedToDeflyWallet                = !!selectedAddress;

  useEffect(() => {
    // Reconnect to the session when the component is mounted
    deflyWallet
      .reconnectSession()
      .then((accounts) => {
        deflyWallet.connector?.on('disconnect', handleDisconnectWalletClick);

        if (accounts.length) {
          setAccounts(accounts);
          setSelectedAddress(accounts[0]);
        }
      })
      .catch((e) => console.log(e));
  }, []);

  return (
    isConnectedToDeflyWallet ?
        <Balances address={ selectedAddress } chain={ chain }
                  accounts={accounts}
                  onChainChange={ handleChainChange }
                  onAddressChange={ handleSelectChange }
                  onDisconnect={ handleDisconnectWalletClick }
                  onActionClick={ handleActionSelect }/>
      :
      <Landing onClick={ handleConnectWalletClick }/>
  );

  function handleConnectWalletClick () {
    deflyWallet
      .connect()
      .then((accounts) => {
        deflyWallet.connector?.on('disconnect', handleDisconnectWalletClick);
        setAccounts(accounts);
        setSelectedAddress(accounts[0]);
      })
      .catch((error) => {
        if (error?.data?.type !== 'CONNECT_MODAL_CLOSED') {
          console.log(error);
        }
      });
  }

  function handleSelectChange (address) {
    setSelectedAddress(address);
  }

  function handleDisconnectWalletClick () {
    deflyWallet.disconnect();

    setSelectedAddress(null);
    setAccounts(null);
  }

  function handleChainChange (chain) {
    setChain(chain);
  }

  async function handleActionSelect (scenarioId) {
    const scenario          = await getScenario(scenarioId, selectedAddress, chain);
    const signedTransaction = await deflyWallet.signTransaction([scenario]);
    await sdkClient(chain).sendRawTransaction(signedTransaction).do();
  }
}

